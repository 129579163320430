// import auth from "../_main/auth"
// import http from "../_main/http"
import router from '../_main/router'

export default {
  cart() {
    router.push({ name: 'cart' })
  },
  casing_size(size) {
    router.push({
      name: 'casing_size',
      params: { id: size.id }
    })
  },
  rod_size(size) {
    router.push({
      name: 'rod_size',
      params: { id: size.id }
    })
  },
  customer(customer) {
    router.push({ name: 'customer', params: { id: customer.id } })
  },
  user(user) {
    router.push({ name: 'user', params: { id: user.id } })
  },
  customerQuote(quote) {
    router.push({ name: 'customerQuote', params: { id: quote.id } })
  },
  adminQuote(quote) {
    router.push({ name: 'adminQuote', params: { id: quote.id } })
  },
  customerOrder(order) {
    router.push({ name: 'customerOrder', params: { id: order.id } })
  },
  partsOrder(order) {
    router.push({ name: 'partsOrder', params: { id: order.id } })
  },
  adminPartsOrder(order) {
    router.push({ name: 'adminPartsOrder', params: { id: order.id } })
  },
  adminOrder(order) {
    router.push({ name: 'adminOrder', params: { id: order.id } })
  },
  drawing(drawing) {
    router.push({ name: 'drawing', params: { id: drawing.id } })
  },
  // userAsset(asset) { router.push({ name: "userAsset", params: { id: asset.id } }) },
  // userAssets()         { router.push({ name: "userAssets"}) },
  drawings() {
    router.push({ name: 'drawings' })
  },
  userPart(user_part) {
    router.push({ name: 'userPart', params: { id: user_part.id } })
  },
  userParts() {
    router.push({ name: 'userParts' })
  },

  userKit(user_kit) {
    router.push({ name: 'userKit', params: { id: user_kit.id } })
  },
  userKits() {
    router.push({ name: 'userKits' })
  },

  userEquipment(user_equipment) {
    router.push({ name: 'userEquipment', params: { id: user_equipment.id } })
  },
  userEquipments() {
    router.push({ name: 'userEquipments' })
  },

  userPump(user_pump) {
    router.push({ name: 'userPump', params: { id: user_pump.id } })
  },
  userPumps() {
    router.push({ name: 'userPumps' })
  },

  equipment(equipment) {
    router.push({ name: 'equipment', params: { id: equipment.id } })
  },
  equipments() {
    router.push({ name: 'equipments' })
  },

  kit(kit) {
    router.push({ name: 'kit', params: { id: kit.id } })
  },
  location(location) {
    router.push({ name: 'location', params: { id: location.id } })
  },
  note(note) {
    router.push({ name: 'note', params: { id: note.id } })
  },
  country(country) {
    router.push({ name: 'country', params: { id: country.id } })
  },
  part(part) {
    router.push({ name: 'part', params: { id: part.id } })
  },
  inventoryItem(item) {
    router.push({ name: 'inventory_item', params: { id: item.id } })
  },
  interchangeablePart(interchangeable_part) {
    router.push({
      name: 'interchangeable_part',
      params: { id: interchangeable_part.id }
    })
  },
  replacementPart(replacement_part) {
    router.push({
      name: 'replacement_part',
      params: { id: replacement_part.id }
    })
  },
  pump(pump) {
    router.push({ name: 'pump', params: { id: pump.id } })
  },
  pump_spec(spec) {
    router.push({ name: 'pump_specification', params: { id: spec.id } })
  },
  assetTag(tag) {
    router.push({ name: 'assetTag', params: { id: tag.id } })
  },
  customerTeam(tag) {
    router.push({ name: 'customerTeam', params: { id: tag.id } })
  },
  customerRegion(tag) {
    router.push({ name: 'customerRegion', params: { id: tag.id } })
  },
  manufacturer(manufacturer) {
    router.push({ name: 'manufacturer', params: { id: manufacturer.id } })
  },
  applicationType(application_type) {
    router.push({
      name: 'application_type',
      params: { id: application_type.id }
    })
  },
  materialType(material_type) {
    router.push({
      name: 'material_type',
      params: { id: material_type.id }
    })
  },
  style(style) {
    router.push({
      name: 'style',
      params: { id: style.id }
    })
  },
  assemblyType(assembly_type) {
    router.push({
      name: 'assembly_type',
      params: { id: assembly_type.id }
    })
  },
  viewJobByID(job_id) {
    router.push({
      name: 'job',
      params: { id: job_id }
    })
  },
  viewJob(job) {
    router.push({
      name: 'job',
      params: { id: job.id }
    })
  },
  viewMyJob(job) {
    router.push({
      name: 'myJob',
      params: { id: job.id }
    })
  },
  assemblyClassification(assembly_classification) {
    router.push({
      name: 'assembly_classification',
      params: { id: assembly_classification.id }
    })
  },
  connectionType(connection_type) {
    router.push({
      name: 'connection_type',
      params: { id: connection_type.id }
    })
  },
  packingType(packing_type) {
    router.push({
      name: 'packing_type',
      params: { id: packing_type.id }
    })
  },
  operationsChecklistItem(operations_checklist_item) {
    router.push({
      name: 'operationsChecklistItem',
      params: { id: operations_checklist_item.id }
    })
  },
  fluidEndConnectionType(fluid_end_connection_type) {
    router.push({
      name: 'fluid_end_connection_type',
      params: { id: fluid_end_connection_type.id }
    })
  },

  partClassification(part_classification) {
    router.push({
      name: 'part_classification',
      params: { id: part_classification.id }
    })
  },
  fluidEnd(fluid_end) {
    router.push({
      name: 'fluid_end',
      params: { id: fluid_end.id }
    })
  },
  powerEnd(power_end) {
    router.push({
      name: 'power_end',
      params: { id: power_end.id }
    })
  },
  size(size) {
    router.push({
      name: 'size',
      params: { id: size.id }
    })
  },
  measurementUnit(measurement_unit) {
    router.push({
      name: 'measurement_unit',
      params: { id: measurement_unit.id }
    })
  },
  // userPart(part)       { router.push({ name: "userPart", params: { id: part.id } }) },
  userDashboard() {
    router.push({ name: 'userDashboard' })
  },
  customerDashboard(customer_id, customer_name) {
    router.push({
      name: 'customerDashboard',
      params: { id: customer_id, name: customer_name }
    })
  },
  pumpsCategory(manufacturer) {
    router.push({
      name: 'pumpManufacturer',
      params: { id: manufacturer.id, name: manufacturer.name }
    })
  },
  pumpCustomersPermission() {
    router.push({
      name: 'pump_fields_customers_permissions'
    })
  },
  pumpCustomerPermission(props) {
    router.push({
      name: 'pump_fields_customer_permissions',
      params: { id: props.id }
    })
  },
  pumpCustomerPermissionAll(props) {
    router.push({
      name: 'pump_fields_customer_permissions_all',
      params: { id: props.id }
    })
  },
  pumpUserPermission(props) {
    router.push({
      name: 'pump_fields_user_permissions',
      params: { id: props.id }
    })
  },
  subscriptionType(subscription) {
    router.push({
      name: 'subscription',
      params: { id: subscription.id }
    })
  },
  importHistoryItems(import_master) {
    router.push({
      name: 'importPastHistoryItems',
      params: { master_key: import_master.id }
    })
  },
  importHistoryItemEdit(import_item) {
    router.push({
      name: 'importedUserPumpEditPage',
      params: { id: import_item.id, master_key: import_item.master_key }
    })
  },
  switchCustomer(customer) {
    if (this.$auth.is.ieqAdmin) {
      this.$http.get(this.$routes.customer_path(customer.id)).then(response => {
        this.$auth.currentCustomer = response.data.customer
        localStorage.setItem('switchCustomerId', customer.id)
        localStorage.setItem(
          'switchedCustomerInfo',
          JSON.stringify(response.data.customer)
        )
        this.$navigate.userDashboard()
      })
    }
  },
  vendor(vendor) {
    router.push({
      name: 'vendor',
      params: { id: vendor.id }
    })
  },
  category(category) {
    router.push({
      name: 'category',
      params: { id: category.id }
    })
  },
  jobType(job) {
    router.push({
      name: 'job_type',
      params: { id: job.id }
    })
  },
  partTags(tag) {
    router.push({
      name: 'part_tag',
      params: { id: tag.id }
    })
  }
}
